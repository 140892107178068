export function getLocalStorageItem(item) {
  if (typeof window !== 'undefined' && navigator.cookieEnabled) {
    return localStorage.getItem(item);
  } else {
    return undefined;
  }
}

export function setLocalStorageItem(item, value) {
  if (typeof window !== 'undefined' && navigator.cookieEnabled) {
    localStorage.setItem(item, value);
  }
}

export function removeLocalStorageItem(item) {
  if (typeof window !== 'undefined' && navigator.cookieEnabled) {
    localStorage.removeItem(item);
  }
}