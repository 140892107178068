import { useRef } from 'react'
import Link from 'next/link'
import IconClose from '@/svgs/close.svg'
import classes from './ProductMobileMenu.module.scss'
import { CSSTransition } from 'react-transition-group'
import { useCustomerContext } from '@/context/CustomerContext'
import { useHeaderContext } from '@/context/HeaderContext'
import { useTheCatchContext } from '@/context/TheCatchContext'
import { useMemberAccountContext } from '@/context/MemberAccountContext'
import { useMarketingContext } from '@/context/MarketingContext'
import { useRouter } from 'next/router'

const ProductMobileMenu = ({ props, pageHandle }) => {
  const { activeExperiments } = useMarketingContext()
  const { closedDoors, setClosedDoors } = useHeaderContext()
  const router = useRouter();

  const isProductPage = router.pathname.startsWith('/products/');

  // AB TEST POC FOR SANITY SETTINGS
  // COULD HAVE INJECTED IN SANITY QUERY, BUT MUCH MORE COMPLICATED TO DETERMINE WHERE/WHAT
  // ALSO, WANTED TO PRESERVE COOKIE AND TEST THESHOLD LOGIC IN MIDDLEWARE
  // TODO: ABSTRACT EXPERIMENT MATCH TO SANITY QUERY (MAYBE NOT POSSIBLE)
  let experimentMatch = false
  experimentMatch = activeExperiments.some(
    (exp) =>
      exp.trigger?.includes(props.nonMemberPrimaryNavigation.handle.current) &&
      exp.variant?.includes('serveVariant'),
  )

  let navigationType
  if (pageHandle === 'purchase-flow' || pageHandle === 'subscriptions' || pageHandle === 'cart') {
    navigationType = pageHandle;
  }

  const { customer, logout } = useCustomerContext()
  const { customerCredit } = useMemberAccountContext()
  const { monthName, year } = useTheCatchContext()
  const { mobileMenuIsOpen, setMobileMenuIsOpen } = useHeaderContext()
  const { menuItems } = isProductPage
    ? props.pdpPrimaryNavigation
    : customer?.is_member || customer?.is_sustainer
      ? props.memberPrimaryNavigation
      : experimentMatch
        ? props.nonMemberPrimaryNavigationBTestVariant
        : props.nonMemberPrimaryNavigation
  const navCTA = customer?.is_member ? props.memberCta : props.nonMemberCta
  const customerService = props.customerService
  const nodeRef = useRef(null)

  let theCatchUrl = `/the-catch/premium-seafood-box-${monthName}-${year}`
  if (customer) {
    if (customer.tags?.includes('PS') || customer.tags?.includes('PSWS')) {
      theCatchUrl = `/the-catch/premium-seafood-box-${monthName}-${year}`
    } else if (
      customer.tags?.includes('SF') ||
      customer.tags?.includes('SF-BI')
    ) {
      theCatchUrl = `/the-catch/seafood-box-${monthName}-${year}`
    } else if (customer.tags?.includes('S')) {
      theCatchUrl = `/the-catch/salmon-box-${monthName}-${year}`
    }
  }

  function handleMobileLogout() {
    setMobileMenuIsOpen(false)
    logout()
  }

  return (
    <CSSTransition
      in={mobileMenuIsOpen}
      timeout={250}
      nodeRef={nodeRef}
      unmountOnExit
      classNames={{
        enter: classes.mobileMenuEnter,
        enterActive: classes.mobileMenuEnterActive,
        enterDone: classes.mobileMenuEnterDone,
        exit: classes.mobileMenuExit,
      }}
    >
      <div
        ref={nodeRef}
        className={`${classes.mobileMenu} ${navigationType ? classes[navigationType] : ''}`}
      >
        <button
          onClick={() => setMobileMenuIsOpen(false)}
          className={classes.mobileMenuCloseBtn}
        >
          <IconClose />
        </button>
        <div className="container">
          {!customer && (
            <div className={classes.mobileMenuSection}>
              <h1>Become A Sitka Seafood Member</h1>
              {/* TODO: this should probably be configurable in sanity and have different text for when logged in and when not logged in */}
              <p>
                Get premium, Wild-Caught Seafood From Alaska Fishermen To Your
                Doorstep.
              </p>
              <Link href={navCTA.ctaUrl ? navCTA.ctaUrl : ''} legacyBehavior>
                <a
                  className={[classes.mobileMainNavBtn, 'btn', 'salmon'].join(
                    ' ',
                  )}
                >
                  {navCTA.ctaText}
                </a>
              </Link>
            </div>
          )}
          {customer && (
            <div className={classes.mobileMenuSection}>
              <div>
                <h1
                  data-testid="customer-display-name"
                  className={classes.customerDisplayName}
                >{`${customer.displayName}`}</h1>
                <p>{`${customer.email}`}</p>
                {customerCredit && (
                  <p
                    data-testid="customer-credit-display"
                    className={classes.customerCreditDisplay}
                  >{`Customer Credit: $${customerCredit.amount}`}</p>
                )}
              </div>
              <ul>
                <li className={classes.mobilePrimaryNavItem}>
                  <Link href={'/account/subscriptions'}>Subscriptions</Link>
                </li>
                <li className={classes.mobilePrimaryNavItem}>
                  <Link href={'/account/account-details'}>Account Details</Link>
                </li>
                <li className={classes.mobilePrimaryNavItem}>
                  <Link href={'/account/order-history'}>Order History</Link>
                </li>
                <li className={classes.mobilePrimaryNavItem}>
                  <Link href={'/account/referrals'}>Referrals</Link>
                </li>
                <li className={classes.mobilePrimaryNavItem}>
                  <a onClick={handleMobileLogout}>Sign Out</a>
                </li>
              </ul>
            </div>
          )}
          <div className={classes.mobileMenuSection}>
            <ul>
              {!closedDoors &&
                menuItems
                  .filter((item) => item?.linkAttributes?.isMobile)
                  .map((item) => {
                    if (item.linkText === 'The Catch') {
                      return (
                        <li
                          className={
                            item?.linkAttributes?.isIndented
                              ? classes.mobilePrimaryNavItemIndented
                              : classes.mobilePrimaryNavItem
                          }
                          key={item._key}
                        >
                          {item?.linkAttributes?.isIndented && ' - '}
                          <Link href={theCatchUrl || '/'}>{item.linkText}</Link>
                        </li>
                      )
                    } else {
                      return (
                        <li
                          className={
                            item?.linkAttributes?.isIndented
                              ? classes.mobilePrimaryNavItemIndented
                              : classes.mobilePrimaryNavItem
                          }
                          key={item._key}
                        >
                          {item?.linkAttributes?.isIndented && ' - '}
                          <Link href={item.linkUrl ? item.linkUrl : '/'}>
                            {item.linkText}
                          </Link>
                        </li>
                      )
                    }
                  })}
            </ul>
          </div>
          <div className={classes.mobileMenuSection}>
            <ul>
              {customerService.customerServiceNavigation.menuItems.map(
                (item) => {
                  return (
                    <li key={item._key}>
                      <Link href={item.linkUrl ? item.linkUrl : ''}>
                        {item.linkText}
                      </Link>
                    </li>
                  )
                },
              )}
            </ul>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default ProductMobileMenu
