import Link from 'next/link'
import IconMenu from '@/svgs/menu.svg'
import IconSearch from '@/svgs/search.svg'
import { useCustomerContext } from '@/context/CustomerContext'
import { useHeaderContext } from '@/context/HeaderContext'
import { useSearchModalContext } from '@/context/SearchModalContext'
import { useTheCatchContext } from '@/context/TheCatchContext'
import { useRouter } from 'next/router'
import ResponsiveComponent from '@/components/ResponsiveComponent'
import { useMarketingContext } from '@/context/MarketingContext'
import { generateCatchUrl } from '@/utils/generateCatchUrl'

const PrimaryNavigation = ({ props, classes }) => {
  const router = useRouter()
  const { closedDoors, setClosedDoors } = useHeaderContext()

  const { activeExperiments } = useMarketingContext()

  // AB TEST POC FOR SANITY SETTINGS
  // NOTE: the nav menu set in the experiment is ignored
  // the ab test menu navs are pulled from the header -- needs refactor
  let experimentMatch = false
  experimentMatch = activeExperiments.some(
    (exp) =>
      exp.trigger?.includes(props.nonMemberPrimaryNavigation.handle.current) &&
      exp.variant?.includes('serveVariant'),
  )

  const customerContext = useCustomerContext()
  const theCatchContext = useTheCatchContext()
  const { customer } = customerContext
  const { setMobileMenuIsOpen } = useHeaderContext()
  const { menuItems } =
    customerContext.customer?.is_member ||
    customerContext.customer?.is_sustainer
      ? props.memberPrimaryNavigation
      : experimentMatch
        ? props.nonMemberPrimaryNavigationBTestVariant
        : props.nonMemberPrimaryNavigation
  const searchModalContext = useSearchModalContext()
  const { setSearchOpen } = searchModalContext
  const { monthName, year } = theCatchContext

  let theCatchUrl = generateCatchUrl(customer, monthName, year)

  const openSearchModal = () => {
    if (router.pathname === '/pages/search') {
      return
    }
    setSearchOpen(true)
  }

  return (
    <ResponsiveComponent
      component="ul"
      display="flex"
      className={classes.navItems}
      desktopBreakpoint={'sm'}
      desktop={
        !closedDoors &&
        menuItems
          .filter((item) => item?.linkAttributes?.isDesktop)
          .map((item) => {
            if (item.linkText === 'The Catch') {
              return (
                <li className={classes.navItem} key={item._key}>
                  <Link href={theCatchUrl || '/'}>{item.linkText}</Link>
                </li>
              )
            } else {
              return (
                <li className={classes.navItem} key={item._key}>
                  <Link href={item.linkUrl ? item.linkUrl : '/'}>
                    {item.linkText}
                  </Link>
                </li>
              )
            }
          })
      }
      mobile={
        <>
          <li className={classes.navItem}>
            <button
              onClick={() => setMobileMenuIsOpen(true)}
              className={classes.navIconButton}
            >
              <IconMenu />
            </button>
          </li>
          <li className={classes.navItem}>
            <IconSearch onClick={() => openSearchModal()} />
          </li>
        </>
      }
    />
  )
}

export default PrimaryNavigation
