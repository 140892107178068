import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
} from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { DateTime } from 'luxon'

const DynamicArticleFiltersDrawer = dynamic(
  () => import('@/components/Layout/ArticleFiltersDrawer'),
)
const DynamicFishermenInfoDrawer = dynamic(
  () => import('@/components/Layout/FishermenInfoDrawer/FishermenInfoDrawer'),
)

const ArticleFiltersDrawerContext = createContext()

function drawerReducer(state, action) {
  switch (action.type) {
    case 'set_info_card': {
      return {
        ...state,
        infoCardFields: action.payload,
      }
    }
    case 'is_fishermen': {
      return {
        ...state,
        isFishermen: true,
      }
    }
    case 'open_fish_info': {
      return {
        ...state,
        isFishInfoOpen: true,
      }
    }
    case 'close_fish_info': {
      return {
        ...state,
        isFishInfoOpen: false,
      }
    }
    case 'open_drawer': {
      return {
        ...state,
        isOpen: true,
      }
    }
    case 'close_drawer': {
      return {
        ...state,
        isOpen: false,
      }
    }
    case 'add_tag_count': {
      return {
        ...state,
        tagCount: action.payload,
      }
    }
    case 'add_original_listings': {
      return {
        ...state,
        originalListings: action.payload,
      }
    }
    case 'update_listings': {
      return {
        ...state,
        listings: action.payload,
      }
    }
    case 'add_filters': {
      return {
        ...state,
        filters: action.payload,
      }
    }
    case 'add_selected_filter': {
      return {
        ...state,
        selectedFilters: [
          ...state.selectedFilters.filter(
            (filter) => filter !== action.payload,
          ),
          action.payload,
        ],
      }
    }
    case 'add_and_filter': {
      return {
        ...state,
        andFilters: [
          ...state.andFilters.filter((filter) => filter !== action.payload),
          action.payload,
        ],
      }
    }
    case 'add_or_filter': {
      return {
        ...state,
        orFilters: [
          ...state.orFilters.filter((filter) => filter !== action.payload),
          action.payload,
        ],
      }
    }
    case 'remove_selected_filter': {
      return {
        ...state,
        selectedFilters: [
          ...state.selectedFilters.filter(
            (filter) => filter !== action.payload,
          ),
        ],
      }
    }
    case 'remove_and_filter': {
      return {
        ...state,
        andFilters: [...state.andFilters.filter((f) => f !== action.payload)],
      }
    }
    case 'remove_or_filter': {
      return {
        ...state,
        orFilters: [...state.orFilters.filter((f) => f !== action.payload)],
      }
    }
    case 'remove_all_filters': {
      return {
        ...state,
        orFilters: [],
        andFilters: [],
        selectedFilters: [],
      }
    }
    default:
      return state
  }
}

const initialState = {
  isOpen: false,
  isFishermen: false,
  filters: {},
  andFilters: [],
  orFilters: [],
  selectedFilters: [],
  listings: [],
  originalListings: [],
  tagCount: {},
  infoCardFields: {},
  isFishInfoOpen: false,
}

export function useArticleFiltersDrawerContext() {
  return useContext(ArticleFiltersDrawerContext)
}

/* TODO: Article and fisherman slideout could just be handled in the pages that are using this context. That
weird little addition of variables in state make this file look and act way more complicated than it really
needs to be. -- ZJ */
/* TODO: I feel like this whole file does not have to be a context file. It could just be a filterer/sorter class 
that we new on loading any page that wants to use it. You could pass it the filters, sorters, and articles/data 
then you could just tell it when to use which filters/sorters and get an array back and update the state in the
component that wants to consume those filtered objects. I feel like that should already exist as a third party
library as well... There is so much needless code here. -- ZJ */
export function ArticleFiltersDrawerProvider({ children }) {
  const router = useRouter()
  const [state, dispatch] = useReducer(drawerReducer, initialState)

  const {
    isOpen,
    infoCardFields,
    isFishInfoOpen,
    filters,
    andFilters,
    orFilters,
    selectedFilters,
    listings,
    originalListings,
    tagCount,
    isFishermen,
  } = state

  // #region STATE UPDATERS

  const setIsFishermen = () => {
    dispatch({ type: 'is_fishermen' })
  }

  const openDrawer = () => {
    dispatch({ type: 'open_drawer' })
  }

  const openFishInfo = () => {
    dispatch({ type: 'open_fish_info' })
  }

  const closeDrawer = () => {
    dispatch({ type: 'close_drawer' })
  }

  const addFilters = (filters) => {
    dispatch({ type: 'add_filters', payload: filters })
  }

  const addTagCount = (tagCount) => {
    dispatch({ type: 'add_tag_count', payload: tagCount })
  }

  const updateListings = (listings) => {
    dispatch({ type: 'update_listings', payload: listings })
  }

  const addOriginalListings = (listings) => {
    dispatch({ type: 'add_original_listings', payload: listings })
    updateListings(listings) // set the listings that will be displayed as well
  }

  const setInfoCard = (info) => {
    dispatch({ type: 'set_info_card', payload: info })
  }

  // #endregion

  const clearFilters = () => {
    dispatch({ type: 'remove_all_filters' })
  }

  // TODO: this sorting could be reimplemented. Not really going to work well if we add more filters.
  // -- ZJ: this doesn't work. When I sort back and forth between newest and oldest, I get varied results
  const sortListings = (listings, mostRecent) => {
    // console.log('listings',listings)
    const sortedListings = listings.sort((a, b) => {
      let aPublishedDate = DateTime.fromISO(a.createdAt)
      let bPublishedDate = DateTime.fromISO(b.createdAt)
      if (a.fields?.publishedDate) {
        aPublishedDate = DateTime.fromISO(a.fields.publishedDate)
      }
      if (b.fields?.publishedDate) {
        bPublishedDate = DateTime.fromISO(b.fields.publishedDate)
      }
      return mostRecent
        ? bPublishedDate > aPublishedDate
          ? 1
          : -1
        : aPublishedDate < bPublishedDate
          ? -1
          : 1
    })
    dispatch({ type: 'update_listings', payload: sortedListings })
  }

  const updateUrlParams = useCallback(() => {
    const url = new URL( 
      typeof window !== 'undefined' ? window.location : ''
    )

    if (orFilters.length > 0) {
      url.searchParams.set('orFilters', decodeURI(orFilters.join('|')))
    } else {
      url.searchParams.delete('orFilters')
    }

    if (andFilters.length > 0) {
      url.searchParams.set('andFilters', decodeURI(andFilters.join('|')))
    } else {
      url.searchParams.delete('andFilters')
    }

    history.pushState({}, '', url)
  }, [andFilters, orFilters])

  const setSortMethod = (value) => {
    if (selectedFilters.length > 0 && value === 'newest') {
      sortListings(listings, true)
    }

    if (selectedFilters.length > 0 && value === 'oldest') {
      sortListings(listings, false)
    }

    if (selectedFilters.length === 0 && value === 'newest') {
      sortListings(originalListings, true)
    }

    if (selectedFilters.length === 0 && value === 'oldest') {
      sortListings(originalListings, false)
    }
  }

  const filterListingsByTags = useCallback(() => {
    let matchingListings = []

    if (selectedFilters.length > 0) {
      matchingListings = originalListings.filter((listing) => {
        const validFilters = []
        Object.keys(filters).forEach((group) => {
          Object.keys(filters[group].options).forEach((opt) => {
            validFilters.push(opt)
            validFilters.push(
              ...Object.keys(filters[group].options[opt].subFilters),
            )
          })
        })
        const validAndFilters = andFilters.filter((f) =>
          validFilters?.includes(f),
        )
        const validOrFilters = orFilters.filter((f) => validFilters?.includes(f))
        const matchesAndFilters = validAndFilters.every((filter) =>
          listing.fields?.articleTags?.some(
            (tag) => tag.value.toLowerCase() === filter.toLowerCase(),
          ),
        )
        const matchesOrFilters =
          validOrFilters.length < 1 ||
          !!listing.fields?.articleTags?.some((tag) =>
            validOrFilters.some(
              (f) => f.toLowerCase() === tag.value.toLowerCase(),
            ),
          )
        return matchesAndFilters && matchesOrFilters
      })
    } else {
      // return all available listings if filters are not set
      matchingListings = originalListings
    }

    dispatch({ type: 'update_listings', payload: matchingListings })
  }, [andFilters, orFilters, originalListings, selectedFilters])

  const subOptionHandler = (filterGroup, filterOption, subFilter) => {
    const parentOptionChecked = selectedFilters?.includes(filterOption)
    const subFilterChecked = selectedFilters?.includes(subFilter)

    if (parentOptionChecked) {
      dispatch({ type: 'remove_selected_filter', payload: filterOption })
      dispatch({ type: 'remove_or_filter', payload: filterOption })
      dispatch({ type: 'remove_and_filter', payload: filterOption })
    }

    if (subFilterChecked) {
      dispatch({ type: 'remove_selected_filter', payload: subFilter })
      dispatch({ type: 'remove_or_filter', payload: subFilter })
      dispatch({ type: 'remove_and_filter', payload: subFilter })
    } else {
      dispatch({ type: 'add_selected_filter', payload: subFilter })
      dispatch({ type: 'add_or_filter', payload: subFilter })
    }
  }

  const optionHandler = (
    optionHasSubfilters,
    filterOptionGroup,
    filterOption,
  ) => {
    const optionChecked = selectedFilters?.includes(filterOption)
    if (optionHasSubfilters) {
      const subFilters = Object.keys(
        filters[filterOptionGroup].options[filterOption].subFilters,
      )

      if (!optionChecked) {
        // if being checked
        dispatch({ type: 'add_selected_filter', payload: filterOption })
        dispatch({ type: 'add_or_filter', payload: filterOption })

        subFilters.forEach((key) => {
          dispatch({ type: 'add_or_filter', payload: key })
          dispatch({ type: 'remove_and_filter', payload: key })
          dispatch({ type: 'add_selected_filter', payload: key })
        })
      } else {
        // if being unchecked
        dispatch({ type: 'remove_selected_filter', payload: filterOption })
        dispatch({ type: 'remove_or_filter', payload: filterOption })
        dispatch({ type: 'remove_and_filter', payload: filterOption })

        subFilters.forEach((key) => {
          dispatch({ type: 'remove_or_filter', payload: key })
          dispatch({ type: 'remove_and_filter', payload: key })
          dispatch({ type: 'remove_selected_filter', payload: key })
        })
      }
    } else {
      if (!optionChecked) {
        // if being checked
        dispatch({ type: 'add_selected_filter', payload: filterOption })
        dispatch({ type: 'add_and_filter', payload: filterOption })
      } else {
        // if being unchecked
        dispatch({ type: 'remove_selected_filter', payload: filterOption })
        dispatch({ type: 'remove_or_filter', payload: filterOption })
        dispatch({ type: 'remove_and_filter', payload: filterOption })
      }
    }
  }

  useEffect(() => {
    if (router.query.andFilters) {
      const andFilterOptions = router.query.andFilters.split('|')
      andFilterOptions.forEach((f) => {
        dispatch({ type: 'add_and_filter', payload: f })
        dispatch({ type: 'add_selected_filter', payload: f })
      })
    }

    if (router.query.orFilters) {
      const orFilterOptions = router.query.orFilters.split('|')
      orFilterOptions.forEach((f) => {
        dispatch({ type: 'add_or_filter', payload: f })
        dispatch({ type: 'add_selected_filter', payload: f })
      })
    }
  }, [router.query.andFilters, router.query.orFilters])

  useEffect(() => {
    // Handle opening and closing filter sidebar if resized page
    const handleResize = () => {
      if (window !== undefined && window.innerWidth >= 1074) {
        dispatch({ type: 'close_drawer' })
        if (!isOpen)
          document.querySelector('html').classList.remove('disable-scroll')
      }
      if (window !== undefined && window.innerWidth < 1074 && isOpen) {
        dispatch({ type: 'open_drawer' })
      }
    }

    if (window !== undefined) {
      window.addEventListener('resize', handleResize)
    }

    // TODO: does this need to be added in here for some reason?
    // return () => {
    //   window.removeEventListener('resize', handleResize)
    // }
  }, [])

  useEffect(() => {
    if (isOpen) document.querySelector('html').classList.add('disable-scroll')
    if (!isOpen)
      document.querySelector('html').classList.remove('disable-scroll')
  }, [isOpen])

  useEffect(() => {
    if (isFishInfoOpen)
      document.querySelector('html').classList.add('disable-scroll')
    if (!isFishInfoOpen)
      document.querySelector('html').classList.remove('disable-scroll')
  }, [isFishInfoOpen])

  useEffect(() => {
    filterListingsByTags()
    updateUrlParams()
  }, [
    filterListingsByTags,
    updateUrlParams,
    selectedFilters,
    orFilters,
    andFilters,
    filters,
  ])

  useEffect(() => {
    router.beforePopState(({ as }) => {
      dispatch({ type: 'close_drawer' })
      return true
    })
    return () => {
      router.beforePopState(() => true)
    }
  }, [router])

  return (
    <ArticleFiltersDrawerContext.Provider
      value={{
        isOpen,
        openFishInfo,
        isFishInfoOpen,
        setInfoCard,
        infoCardFields,
        setIsFishermen,
        isFishermen,
        setSortMethod,
        addTagCount,
        tagCount,
        filters,
        filterListingsByTags,
        openDrawer,
        closeDrawer,
        addFilters,
        optionHandler,
        subOptionHandler,
        dispatch,
        selectedFilters,
        listings,
        originalListings,
        addOriginalListings,
        sortListings,
        clearFilters,
      }}
    >
      {isOpen && <DynamicArticleFiltersDrawer />}
      {isFishInfoOpen && <DynamicFishermenInfoDrawer />}
      {children}
    </ArticleFiltersDrawerContext.Provider>
  )
}
