import Layout from '@/components/Layout'
import '../styles/global.scss'
import 'react-dropdown/style.css'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import TagManager from 'react-gtm-module'
import { setLocalStorageItem } from '@/utils/localStorageHelper'
import sanityClient from '../services/sanityClient'
import { bebas, frank } from '@/utils/fonts'
import CustomZendeskWidget from '@/components/CustomZendeskWidget/CustomZendeskWidget'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// THE QUERY IS NOW FETCHED IN getInitialProps BELOW
const QUERY = `
[
  *[handle.current == "header-settings"] {
    _type,
    _rev,
    "objectID": _id,
    _createdAt,
    _updatedAt,
    title,
    header,
    handle,
    logo{
      asset->{
        published,
        type,
        sha1hash,
        metadata,
        assetId,
        originalFilename,
        extension,
        uploadId,
        path,
        mimeType,
        url,
      },
    },
    memberPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    nonMemberPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    nonMemberPrimaryNavigationBTestVariant->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    pdpPrimaryNavigation->{
      title,
      handle,
      menuItems[]{
        _key,
        linkText,
        linkUrl,
        linkAttributes
      }
    },
    primaryAnnouncement,
    memberCta,
    nonMemberCta,
    Search,
    customerService{
      header,
      subheader,
      customerServiceNavigation->{
        title,
        handle,
        menuItems[]{
          _key,
          linkText,
          linkUrl
        }
      }
    },
  },
  *[handle.current == "footer-settings"] {
    _type,
    _rev,
    "objectID": _id,
    _createdAt,
    _updatedAt,
    title,
    header,
    handle,
    logo{
      asset->{
        published,
        type,
        sha1hash,
        metadata,
        assetId,
        originalFilename,
        extension,
        uploadId,
        path,
        mimeType,
        url,
      },
    },
    footerProps,
    copyright,
    footerNavigationList[]{
      _key,
      title,
      navigation->{
        title,
        handle,
        menuItems[]{
          _key,
          linkText,
          linkUrl
        }
      }
    },
    socialLinks[]{
      _key,
      platform,
      socialLink
    },
    termsLinks[]{
      _key,
      linkText,
      linkUrl
    },
    copyright,
    emailSignup
  }
]
`

const AppContainer = ({ Component, pageProps, settings }) => {
  const router = useRouter()
  const headerSettings = settings?.[0]?.[0] || null
  const footerSettings = settings?.[1]?.[0] || null
  const searchLinks = settings?.[2]?.[0] || null

  useEffect(() => {
    const addonParam = router.query.addon
    if (addonParam) {
      setLocalStorageItem('allowAddon', `${addonParam}`)
    }
  }, [router.query])

  // INITIALIZE MARKETING TAGS
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !== 'development' && typeof window !== 'undefined') {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      })
    }
  }, [router.isReady])

  return (
    <main className={`${bebas.variable} ${frank.variable}`}>
      <Layout
        headerSettings={headerSettings}
        footerSettings={footerSettings}
        searchLinks={searchLinks}
      >
        <Component {...pageProps} />
      </Layout>

      <Script
        id="elevar"
        strategy="beforeInteractive"
        onReady={() => {
          console.debug('Elevar script LOADED')
        }}
        onError={() => {
          console.error('Elevar script FAILED TO LOAD')
        }}
      >
        {`
        async function loadElevar() {
          try {
            const settings = {};
            const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/acaae91fdd050d2cbe90e9794654c3779b11e2db/config.js")).default;
            const scriptUrl = settings.proxyPath
              ? settings.proxyPath + config.script_src_custom_pages_proxied
              : config.script_src_custom_pages;
            if (scriptUrl) {
              const { handler } = await import(scriptUrl);
              await handler(config, settings);
            }
          } catch (error) {
            console.error("Elevar Error:", error);
          }
        }
        loadElevar();
        `}
      </Script>
      <CustomZendeskWidget />
    </main>
  )
}

// FETCH DATA ON SERVER SIDE
AppContainer.getInitialProps = async () => {
  const settings = await sanityClient.fetch(QUERY)
  return { settings }
}

export default AppContainer
