import { useEffect } from 'react';
import classes from './ProductMainNavigation.module.scss'
import Link from 'next/link'
import Image from 'next/image'
import { useHeaderContext } from '@/context/HeaderContext'
import { useCustomerContext } from '@/context/CustomerContext'
import { useMarketingContext } from '@/context/MarketingContext'

import ResponsiveComponent from '@/components/ResponsiveComponent'
import ProductPrimaryNavigation from './ProductPrimaryNavigation'
import { useRouter } from 'next/router'

const ProductMainNavigation = ({ props }) => {
  const customerContext = useCustomerContext()
  const { activeExperiments } = useMarketingContext()
  const { closedDoors, setMobileMenuIsOpen } = useHeaderContext()
  const router = useRouter();
  const { setClosedDoors } = useHeaderContext()

  const isProductPage = router.pathname.startsWith('/products/');

  const isMember = customerContext.customer?.is_member || customerContext.customer?.is_sustainer;
  const experimentMatch = activeExperiments.some(
    (exp) =>
      exp.trigger?.includes(props.nonMemberPrimaryNavigation.handle.current) &&
      exp.variant?.includes('serveVariant')
  );

  const { menuItems } = isProductPage
    ? props.pdpPrimaryNavigation
    : isMember
    ? props.memberPrimaryNavigation
    : experimentMatch
    ? props.nonMemberPrimaryNavigationBTestVariant
    : props.nonMemberPrimaryNavigation || [];

  useEffect(() => {
    setClosedDoors(false)
  }, []);

  return (
    <div className={`${classes.mainNavigation} ${classes.mainNavContainer}`}>
      <div className={classes.navLogo}>
        <div className={classes.navLogoContainer}>
          <Link href="/">
            <Image
              src={props.logo.asset.url}
              fill
              alt={props.logo.asset.alt || 'Logo'}
              priority
            />
          </Link>
        </div>
      </div>

      <ResponsiveComponent
        component="ul"
        display="flex"
        className={classes.navItems}
        desktopBreakpoint={'sm'}
        desktop={
          <>
            {!closedDoors &&
              Array.isArray(menuItems) &&
              menuItems
                .filter((item) => item?.linkAttributes?.isDesktop)
                .map((item) => (
                  <li className={classes.navItem} key={item._key}>
                    <Link href={item.linkUrl || '/'}>
                      {item.linkText}
                    </Link>
                  </li>
                ))}
            <ProductPrimaryNavigation props={props} classes={classes} />
          </>
        }
        mobile={
          <>
            <ProductPrimaryNavigation props={props} classes={classes} />
            <li className={classes.navItem}>
              <button
                onClick={() => setMobileMenuIsOpen(true)}
                className={classes.navIconButton}
              >
                ☰
              </button>
            </li>
          </>
        }
      />
    </div>
  )
}

export default ProductMainNavigation;
