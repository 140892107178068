import { createContext, useContext, useState, useEffect } from 'react'
import { transformEdges } from '@/gql/index.js'
import { encode } from 'js-base64'
import { get, set, remove } from 'es-cookie'
import { dataLayerLogin } from '@/utils/dataLayer'
import { useRouter } from 'next/router'
import { queryShopifyStorefront } from '@/services/shopifyStorefront'
import { buildSourceEntryId } from '@/utils/sourceEntryId'

export const CustomerContext = createContext()

export function useCustomerContext() {
  return useContext(CustomerContext)
}

export function CustomerProvider({ children }) {
  const [customer, setCustomer] = useState(undefined)
  const [customerLoading, setCustomerLoading] = useState(false)
  const router = useRouter()

  function getCustomerAccessToken() {
    return get('customerAccessToken')
  }

  useEffect(() => {
    const customerAccessToken = getCustomerAccessToken()
    // console.log("customerAccessToken:", customerAccessToken)
    if (customerAccessToken) {
      // console.log('Fetching Customer Data');
      getCustomer({ accessToken: customerAccessToken })
    } else {
      // console.log('Customer Already Loaded');
      setCustomerLoading(false)
    }
  }, [])

  async function createCustomerAccessToken({ email, password }) {
    const response = await queryShopifyStorefront({
      query: CUSTOMER_ACCESS_TOKEN_CREATE,
      variables: {
        input: {
          email,
          password,
        },
      },
    })
    const { customerAccessTokenCreate, errors } = response
    if (errors && errors.length) {
      return { customerAccessTokenCreateErrors: errors }
    }
    return { customerAccessTokenCreate }
  }

  function hasOneOfTags({ customer, tags }) {
    return (
      customer?.tags?.length > 0 &&
      customer.tags.some((t) =>
        tags.map((t2) => t2.toLowerCase())?.includes(t.toLowerCase()),
      )
    )
  }

  function customerHasOneOfTags(tags) {
    return hasOneOfTags({ customer, tags })
  }

  function hasTag({ customer, tag }) {
    return customer?.tags?.length > 0 && customer.tags.some((t) => t === tag)
  }

  function customerHasTag(tag) {
    return hasTag({ customer, tag })
  }

  async function getCustomer({ accessToken, expiresAt }) {
    setCustomerLoading(true)
    const getCustomerResult = await queryShopifyStorefront({
      query: GET_CUSTOMER,
      variables: {
        customerAccessToken: accessToken,
      },
    })
    const customer = getCustomerResult?.customer
    setCustomerLoading(false)
    if (customer && expiresAt) {
      set('customerAccessToken', accessToken, {
        expires: new Date(expiresAt),
        path: '/',
      })
    }

    if (!!customer) {
      customer.is_member = hasOneOfTags({
        customer,
        tags: ['Employee', 'Member', 'PremiumMember', 'Prepaid'],
      })
      customer.is_sustainer = hasOneOfTags({
        customer,
        tags: ['KingSustainer', 'SockeyeSustainer'],
      })
    }

    if (customer?.addresses?.edges.length > 0) {
      customer.addresses = transformEdges(customer.addresses)
    }

    setCustomer(customer)
    if (customer) {
      // no longer needed with GTM?
      // trackEvent('setCustomer', customer)
    } else {
      setCustomer(customer)
      remove('customerAccessToken') // Customer access token was not valid - log the customer out
    }
    console.debug('customer:', customer)
    return customer
  }

  async function login({ email, password }) {
    const { customerAccessTokenCreateErrors, customerAccessTokenCreate } =
      await createCustomerAccessToken({ email, password })
    if (customerAccessTokenCreateErrors) {
      return { errors: customerAccessTokenCreateErrors }
    }
    if (customerAccessTokenCreate.userErrors.length) {
      return { errors: customerAccessTokenCreate.userErrors }
    }
    const customerAccessToken = customerAccessTokenCreate.customerAccessToken
    const customer = await getCustomer({
      accessToken: customerAccessToken.accessToken,
      expiresAt: customerAccessToken.expiresAt,
    })
    dataLayerLogin({ customer, url: router.pathname })
    return customer
  }

  async function logout() {
    const { customerAccessTokenDelete } = await queryShopifyStorefront({
      query: CUSTOMER_ACCESS_TOKEN_DELETE,
      variables: { customerAccessToken: getCustomerAccessToken() },
    })
    const { deletedAccessToken, userErrors } = customerAccessTokenDelete
    if (deletedAccessToken) {
      setCustomer(null)
      remove('customerAccessToken')
    }
    return { deletedAccessToken, errors: userErrors }
  }

  async function register({ firstName, lastName, email, password }) {
    const { customerCreate } = await queryShopifyStorefront({
      query: CUSTOMER_CREATE,
      variables: {
        input: {
          firstName,
          lastName,
          email,
          password,
        },
      },
    })
    const { customer, customerUserErrors } = customerCreate
    return { data: customer, errors: customerUserErrors }
  }

  async function recover({ email }) {
    const { customerRecover } = await queryShopifyStorefront({
      query: CUSTOMER_RECOVER,
      variables: {
        email,
      },
    })
    const { customerUserErrors } = customerRecover
    return { errors: customerUserErrors }
  }

  async function activate({ password, customerId, activationToken }) {
    const id = encode(
      buildSourceEntryId({ id: customerId, objectType: 'Customer' }),
    )
    const { customerActivate } = await queryShopifyStorefront({
      query: CUSTOMER_ACTIVATE,
      variables: {
        id,
        input: {
          password,
          activationToken,
        },
      },
    })
    const { customer, customerUserErrors } = customerActivate
    return { data: customer, errors: customerUserErrors }
  }

  async function reset({ password, customerId, resetToken }) {
    const id = encode(
      buildSourceEntryId({ id: customerId, objectType: 'Customer' }),
    )
    const response = await queryShopifyStorefront({
      query: CUSTOMER_RESET,
      variables: {
        id,
        input: {
          password,
          resetToken,
        },
      },
    })
    const { customerUserErrors, customer } = response.customerReset
    return { data: customer, errors: customerUserErrors }
  }

  async function updateCustomer(updatedData) {
    const { customerUpdate } = await queryShopifyStorefront({
      query: CUSTOMER_UPDATE,
      variables: {
        accessToken: getCustomerAccessToken(),
        updatedCustomer: updatedData,
      },
    })
    setCustomer(customerUpdate.customer)
    console.debug('customer:', customer)
    // TODO: we need to validate any errors that may have come through the API call here
    return customer
  }

  async function updateCustomer(updatedData) {
    const { customerUpdate } = await queryShopifyStorefront({
      query: CUSTOMER_UPDATE,
      variables: {
        accessToken: getCustomerAccessToken(),
        updatedCustomer: updatedData,
      },
    })
    setCustomer(customerUpdate.customer)
    console.debug('customer:', customer)
    // TODO: we need to validate any errors that may have come through the API call here
    return customer
  }

  return (
    <CustomerContext.Provider
      value={{
        customer,
        setCustomer,
        customerLoading,
        login,
        logout,
        register,
        recover,
        activate,
        reset,
        updateCustomer,
        customerHasOneOfTags,
        customerHasTag,
        getCustomerAccessToken,
      }}
    >
      {children}
    </CustomerContext.Provider>
  )
}

//#region GRAPHQL
const CUSTOMER_SCHEMA = `
    id
    email
    acceptsMarketing
    createdAt
    updatedAt
    displayName
    lastName
    firstName
    phone
    tags
    defaultAddress {
      address1
      address2
      city
      company
      country
      countryCodeV2
      firstName
      formatted
      formattedArea
      id
      lastName
      latitude
      longitude
      name
      phone
      province
      provinceCode
      zip
    }
    addresses (first: 50) {
      edges {
        node {
          address1
          address2
          city
          company
          country
          countryCodeV2
          firstName
          formatted
          formattedArea
          id
          lastName
          name
          phone
          province
          provinceCode
          zip
        }
      }
    }
`

const CUSTOMER_ACCESS_TOKEN_CREATE = `mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    userErrors {
      field
      message
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
  }
}`

const CUSTOMER_ACCESS_TOKEN_DELETE = `mutation customerAccessTokenDelete($customerAccessToken: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
    deletedAccessToken
    deletedCustomerAccessTokenId
    userErrors {
      field
      message
    }
  }
}`

const GET_CUSTOMER = `query getCustomer($customerAccessToken: String!) {
  customer(customerAccessToken: $customerAccessToken) {
    ${CUSTOMER_SCHEMA}
  }
}`

const CUSTOMER_CREATE = `mutation customerCreate($input: CustomerCreateInput!) {
  customerCreate(input: $input) {
    customer {
      id
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`

const CUSTOMER_RECOVER = `mutation customerRecover($email: String!) {
  customerRecover(email: $email) {
    customerUserErrors {
      code
      field
      message
    }
  }
}`

const CUSTOMER_ACTIVATE = `mutation customerActivate($id: ID!, $input: CustomerActivateInput!) {
  customerActivate(id: $id, input: $input) {
    customer {
      id
      email
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`

const CUSTOMER_RESET = `mutation customerReset($id: ID!, $input: CustomerResetInput!) {
  customerReset(id: $id, input: $input) {
    customer {
      id
      email
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}`

const CUSTOMER_UPDATE = `mutation UpdateCustomer($accessToken:String!, $updatedCustomer:CustomerUpdateInput!) {
  customerUpdate(
    customer: $updatedCustomer
    customerAccessToken: $accessToken
  ) {
    customer {
      ${CUSTOMER_SCHEMA}
    }
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      message
      field
      code
    }
  }
}`
//#endregion
