import { useEffect } from 'react'

const CustomZendeskWidget = () => {
  useEffect(() => {
    const isMobile = () => 
      typeof window !== 'undefined' &&
      window.innerWidth <= 768 // 768px is the breakpoint for mobile

      
    const closeMessageIframe = document.querySelector('iframe[title="Close message"]')
    const messageFromCompanyIframe = document.querySelector('iframe[title="Message from company"]')

    const checkIframe = setInterval(() => {
      // get the iframe element of the Zendesk widget
      const iframe = document.getElementById('launcher')
      if (iframe) {
        if (isMobile()) {
          iframe.style.left = '0px'
          iframe.style.right = 'unset'
          iframe.style.display = 'none'
          closeMessageIframe.style.display = 'none'
          messageFromCompanyIframe.style.display = 'none'
        } else {
          iframe.style.right = '0px'
          iframe.style.left = 'unset'
          closeMessageIframe.style.display = 'block'
          messageFromCompanyIframe.style.display = 'block'
        }

        // Clear the interval once the iframe is found
        clearInterval(checkIframe)
      }
    }, 1000)

    return () => clearInterval(checkIframe)
  }, [])

  // There's no need to render anything in the DOM
  return null
}

export default CustomZendeskWidget
