import { queryShopifyStorefront } from '../services/shopifyStorefront'
import { PRODUCT_SCHEMA } from './getProducts'

export const getCollectionByHandle = async (handle, withProducts = true) => {
  const WITH_PRODUCTS = `
    products(first: 100) {
      edges {
        node {
          ${PRODUCT_SCHEMA}
        }
      }
    }
  `
  //#region QUERY
  const query = `
    {
      collectionByHandle(handle: "${handle}") {
        id
        title
        handle
        description
        ${withProducts ? WITH_PRODUCTS : ''}
      }
    }
  `
  //#endregion

  const { collectionByHandle: collection } = await queryShopifyStorefront({
    query,
  })

  if (!collection) {
    return null
  }

  if (!withProducts) {
    return {
      sourceEntryId: collection.id,
      content: {
        title: collection.title,
        description: collection.description,
        handle: collection.handle,
      },
    }
  }
  const products = collection.products.edges.map(({ node }) => node)

  return {
    sourceEntryId: collection.id,
    content: {
      title: collection.title,
      description: collection.description,
      handle: collection.handle,
    },
    products: products.map((product) => ({
      ...product,
      sourceEntryId: product.id,
      sourceId: product.id,
      metafields: product.metafields.filter((el) => !!el),
      content: {
        sourceEntryId: product.id,
        sourceId: product.id,
        handle: product.handle,
        title: product.title,
        description: product.description,
        metafields: product.metafields.filter((el) => !!el),
        options: product.options,
        featuredMedia: {
          id: product.images.edges[0]?.node?.id || null,
          src: product.images.edges[0]?.node?.originalSrc || '',
          altText: product.images.edges[0]?.node?.altText || '',
          thumbnailSrc:
            product.images.edges[0]?.node?.originalSrc + '&width=100',
          type: 'IMAGE',
        },
        media: product.images.edges.map(({ node }) => ({
          id: node.id,
          src: node.originalSrc,
          altText: node.altText || '',
          thumbnailSrc: node.originalSrc + '&width=100',
        })),
      },
      variants: product.variants.edges.map(({ node }) => ({
        ...node,
        id: node.id,
        sourceEntryId: node.id,
        sku: node.sku,
        availableForSale: node.availableForSale,
        price: +node.price?.amount,
        compareAtPrice: node?.compareAtPrice?.amount || 0,
        weight: node.weight,
        productHandle: product.handle,
        quantityAvailable: node.quantityAvailable,
        metafields: node.metafields.filter((el) => el),
        content: {
          title: node.title,
          variantEntryId: node.id,
          selectedOptions: node.selectedOptions,
        },
        featuredMedia: {
          src: product.images.edges[0]?.node?.originalSrc || '',
          altText: product.images.edges[0]?.node?.altText || '',
          thumbnailSrc:
            product.images.edges[0]?.node?.originalSrc + '&width=100',
        },
        sellingPlans: node?.sellingPlanAllocations?.nodes?.map(
          ({ sellingPlan }) => sellingPlan,
        ),
      })),
    })),
  }
}

export const getSwapsCollection = async () => {
  const swapableCollection = await getCollectionByHandle('swapables')
  return swapableCollection
}

export const getAddonCollection = async () => {
  const addonCollection = await getCollectionByHandle('addons')
  return addonCollection
}
