import { captureException } from "@sentry/nextjs"

export async function queryShopifyStorefront({ query, variables, keepalive = false }) {
  const response = await fetch(`https://${process.env.NEXT_PUBLIC_MYSHOPIFY_DOMAIN}/api/${process.env.NEXT_PUBLIC_STOREFRONT_API_VERSION}/graphql.json`, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-Shopify-Storefront-Access-Token': process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ACCESS_TOKEN,
    },
    body: JSON.stringify({ query, variables }),
    keepalive
  })

  if (response.ok) {
    const { data, errors } = await response.json()
    const unexpectedErrors = filterErrors(errors)
    if (unexpectedErrors.length > 0) {
      console.error('shopify storefront returned errors: ', unexpectedErrors)
      captureException(new Error(JSON.stringify(unexpectedErrors)))
    }
    return data
  } else {
    const e = await response.json()
    console.error(`${response.status} shopify storefront API response`, e)
    captureException(new Error(JSON.stringify(e)))
    return undefined
  }
}

function filterErrors(errors) {
  const expectedErrors = [`Shipping address can't be blank`]

  function errorIsExpected(e) {
    return expectedErrors?.includes(e.message)
  }

  return errors?.filter(e => !errorIsExpected(e)) || []
}
